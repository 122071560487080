import { useMemo } from 'react'
import { useMatches } from 'react-router-dom'

import { EnvType } from 'meta/app'
import { Routes } from 'meta/routes'

import { useAppEnv } from 'hooks/useEnv'

const indexRoutesExcluded = [
  Routes.CartographerEvent,
  Routes.CartographerCollection,
  Routes.CheckoutCancel,
  Routes.CheckoutProcess,
  Routes.LocationPhp,
  Routes.Search,
].map((route) => route.path)

const followRoutesExcluded = [
  Routes.CartographerEvent,
  Routes.CartographerCollection,
  Routes.CheckoutCancel,
  Routes.CheckoutProcess,
  Routes.LocationPhp,
].map((route) => route.path)

export const useRobotsContent = (): string => {
  const matches = useMatches()
  const appEnv = useAppEnv()

  return useMemo<string>(() => {
    const includeRoutes = (routesExcluded: Array<string>): boolean =>
      appEnv === EnvType.production &&
      !matches.find((match) => routesExcluded.find((route) => match.pathname.includes(route)))

    const index = includeRoutes(indexRoutesExcluded) ? `index` : `noindex`
    const follow = includeRoutes(followRoutesExcluded) ? `follow` : `nofollow`

    return `${index}, ${follow}`
  }, [appEnv, matches])
}
