import { createRoute } from 'meta/routes/createRoute'
import { QueryParams, RouteParams } from 'meta/routes/params'
import { Root } from 'meta/routes/root'

const CartographerEvent = createRoute<RouteParams['Cartographer'], QueryParams['Cartographer']>({
  path: 'cartographer/:eventId',
})
const CartographerCollection = createRoute<RouteParams['Cartographer'], QueryParams['Cartographer']>({
  path: 'cartographer/collection/:typeTicketCollectionId',
})
const ChangeName = createRoute({ path: 'change-name' })
const CheckoutCancel = createRoute<undefined, QueryParams['CheckoutCancel']>({ path: 'checkout/cancel' })
const CheckoutProcess = createRoute<undefined, QueryParams['CheckoutProcess']>({ path: 'checkout/process' })
const Collection = createRoute<RouteParams['Collection']>({ path: 'collection/:id' })
const CollectionIframe = createRoute<RouteParams['Collection']>({ path: 'collection/:id/Iframe' })
const CookiePolicy = createRoute({ path: 'cookie-policy' })
const Faq = createRoute({ path: 'faq' })
const Error = createRoute<undefined, QueryParams['Error']>({ path: 'error' })
const Event = createRoute<RouteParams['Event']>({ path: 'event/:codeUrl' })
const EventIframe = createRoute<RouteParams['Event']>({ path: 'event/:codeUrl/Iframe' })
const Governance = createRoute({ path: 'governance' })
const Home = createRoute({ path: '' })
const Location = createRoute<RouteParams['Location']>({ path: 'location/:locationId' })
const LocationPhp = createRoute({ path: 'location.php' })
const NotFound = createRoute({ path: '404' })
const PrivacyPolicy = createRoute({ path: 'privacy' })
const Profile = createRoute({ path: 'profile' })
const ProfileChangeData = createRoute({ path: 'changeData', parent: Profile })
const ProfileChangePassword = createRoute({ path: 'changePassword', parent: Profile })
const ProfileChangeTicketName = createRoute({ path: 'changeTicketName', parent: Profile })
const ProfileCreditCards = createRoute({ path: 'creditCards', parent: Profile })
const ProfileResale = createRoute({ path: 'resale', parent: Profile })
const ProfileTransactions = createRoute({ path: 'transactions', parent: Profile })
const ProfileUndoResale = createRoute({ path: 'undoResale', parent: Profile })
const Promoter = createRoute<RouteParams['Event']>({ path: 'promoter/:codeUrl' })
const PurchaseForm = createRoute<undefined, QueryParams['PurchaseForm']>({ path: 'purchase/form' })
const Refund = createRoute<RouteParams['Refund']>({ path: 'refund/:codeTransaction' })
const Resale = createRoute({ path: 'resale' })
const Search = createRoute<undefined, QueryParams['Search']>({ path: 'search' })
const Success = createRoute({ path: 'success' })
const SupportConversation = createRoute<RouteParams['SupportConversation']>({ path: 'support/conversation/:code' })
const SupportForm = createRoute({ path: 'support/form' })
const Terms = createRoute({ path: 'terms' })
const TicketGeneration = createRoute({ path: 'ticket-generation' })
const Users = createRoute({ path: 'users' })
const UsersEmail = createRoute({ path: 'email', parent: Users })
const UsersEmailConfirm = createRoute<RouteParams['UsersEmailConfirm']>({ path: 'confirm/:token', parent: UsersEmail })
const UsersPassword = createRoute({ path: 'password', parent: Users })
const UsersPasswordReset = createRoute({ path: 'reset', parent: UsersPassword })
const UsersPasswordUpdate = createRoute<undefined, QueryParams['UsersPasswordUpdate']>({
  path: 'update',
  parent: UsersPassword,
})
const Wallet = createRoute({ path: 'wallet' })

export const Routes = {
  CartographerEvent,
  CartographerCollection,
  Collection,
  CollectionIframe,
  ChangeName,
  CheckoutCancel,
  CheckoutProcess,
  CookiePolicy,
  Error,
  Event,
  EventIframe,
  Faq,
  Governance,
  Home,
  Location,
  LocationPhp,
  NotFound,
  PrivacyPolicy,
  Profile,
  ProfileChangeData,
  ProfileChangePassword,
  ProfileChangeTicketName,
  ProfileCreditCards,
  ProfileResale,
  ProfileTransactions,
  ProfileUndoResale,
  Promoter,
  PurchaseForm,
  Refund,
  Resale,
  Root,
  Search,
  Success,
  SupportConversation,
  SupportForm,
  Terms,
  TicketGeneration,
  Users,
  UsersEmail,
  UsersEmailConfirm,
  UsersPassword,
  UsersPasswordReset,
  UsersPasswordUpdate,
  Wallet,
}
