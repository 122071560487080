import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { Routes } from 'meta/routes'

const onlyContentRoutes: Record<string, boolean> = {
  [Routes.CartographerEvent.parts[0]]: true,
}

type Returned = { onlyContent: boolean }

export const useShowOnlyContent = (): Returned => {
  const { pathname } = useLocation()

  return useMemo<Returned>(() => {
    const parts = pathname.split('/')
    const onlyContent = onlyContentRoutes[parts[1]] || parts[parts.length - 1].toLowerCase() === 'iframe'
    return { onlyContent }
  }, [pathname])
}
