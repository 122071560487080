import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { Routes } from 'meta/routes'

const disabledRoutes: Record<string, boolean> = {
  [Routes.CartographerEvent.parts[0]]: true,
}

type Returned = { disableCookieBanner: boolean }

export const useDisableCookieBanner = (): Returned => {
  const { pathname } = useLocation()

  return useMemo<Returned>(() => {
    const disableCookieBanner = disabledRoutes[pathname.split('/')[1]]
    return { disableCookieBanner }
  }, [pathname])
}
