import { useMemo } from 'react'
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom'

import { Routes } from 'meta/routes'

import Profile from 'components/Auth/Profile'
import Layout from 'components/Layout'
import Cartographer from 'components/Pages/Cartographer'
import ChangeName from 'components/Pages/ChangeName'
import CheckoutCancel from 'components/Pages/CheckoutCancel'
import CheckoutProcess from 'components/Pages/CheckoutProcess'
import Collection from 'components/Pages/Collection/Collection'
import CollectionIFrame from 'components/Pages/Collection/CollectionIFrame/CollectionIFrame'
import CookiePolicy from 'components/Pages/CookiePolicy'
import Error from 'components/Pages/Error'
import ErrorWrapper from 'components/Pages/Error/ErrorWrapper'
import Event from 'components/Pages/Event'
import Faq from 'components/Pages/FAQ'
import Governance from 'components/Pages/Governance'
import Home from 'components/Pages/Home'
import IFrame from 'components/Pages/Iframe'
import Location from 'components/Pages/Location'
import LocationRedirect from 'components/Pages/LocationRedirect'
import NotFound from 'components/Pages/NotFound'
import PrivacyPolicy from 'components/Pages/PrivacyPolicy'
import ProfileChangeData from 'components/Pages/ProfileChangeData'
import ProfileChangePassword from 'components/Pages/ProfileChangePassword'
import ProfileChangeTicketName from 'components/Pages/ProfileChangeTicketName'
import ProfileCreditCards from 'components/Pages/ProfileCreditCards'
import ProfileResale from 'components/Pages/ProfileResale'
import ProfileTransactions from 'components/Pages/ProfileTransactions'
import ProfileUndoResale from 'components/Pages/ProfileUndoResale'
import Promoter from 'components/Pages/Promoter'
import PurchaseForm from 'components/Pages/PurchaseForm'
import Refund from 'components/Pages/Refund'
import Resale from 'components/Pages/Resale'
import Search from 'components/Pages/Search'
import Success from 'components/Pages/Success'
import SupportConversation from 'components/Pages/SupportConversation'
import SupportForm from 'components/Pages/SupportForm'
import Terms from 'components/Pages/Terms'
import TicketGeneration from 'components/Pages/TicketGeneration'
import UsersEmail from 'components/Pages/UsersEmail'
import UsersEmailConfirm from 'components/Pages/UsersEmailConfirm'
import UsersPassword from 'components/Pages/UsersPassword'
import UsersPasswordReset from 'components/Pages/UsersPasswordReset'
import UsersPasswordUpdate from 'components/Pages/UsersPasswordUpdate'
import Wallet from 'components/Pages/Wallet'

type Returned = {
  Router: JSX.Element
}

export const useRouter = (): Returned => {
  const routes = useMemo(() => {
    const withLang = (path: string): string => `:lang?/${path}`

    const children = (
      <Route element={<Layout />} errorElement={<ErrorWrapper />} path="/">
        <Route path={withLang(Routes.CartographerEvent.path)} element={<Cartographer />} />
        <Route path={withLang(Routes.CartographerCollection.path)} element={<Cartographer />} />
        <Route path={withLang(Routes.ChangeName.path)} element={<ChangeName />} />
        <Route path={withLang(Routes.CheckoutCancel.path)} element={<CheckoutCancel />} />
        <Route path={withLang(Routes.CheckoutProcess.path)} element={<CheckoutProcess />} />
        <Route path={withLang(Routes.Collection.path)} element={<Collection />} />
        <Route path={withLang(Routes.CollectionIframe.path)} element={<CollectionIFrame />} />
        <Route path={withLang(Routes.CookiePolicy.path)} element={<CookiePolicy />} />
        <Route path={withLang(Routes.Event.path)} element={<Event />} />
        <Route path={withLang(Routes.EventIframe.path)} element={<IFrame />} />
        <Route path={withLang(Routes.Error.path)} element={<Error />} />
        <Route path={withLang(Routes.Faq.path)} element={<Faq />} />
        <Route path={withLang(Routes.Governance.path)} element={<Governance />} />
        <Route path={withLang(Routes.Home.path)} element={<Home />} />
        <Route path={withLang(Routes.Location.path)} element={<Location />} />
        <Route path={withLang(Routes.LocationPhp.path)} element={<LocationRedirect />} />
        <Route path={withLang(Routes.PrivacyPolicy.path)} element={<PrivacyPolicy />} />
        <Route path={withLang(Routes.Profile.path)} element={<Profile />}>
          <Route index element={<Navigate to={Routes.ProfileTransactions.generatePath()} replace />} />
          <Route path={Routes.ProfileChangeData.path} element={<ProfileChangeData />} />
          <Route path={Routes.ProfileChangePassword.path} element={<ProfileChangePassword />} />
          <Route path={Routes.ProfileChangeTicketName.path} element={<ProfileChangeTicketName />} />
          <Route path={Routes.ProfileCreditCards.path} element={<ProfileCreditCards />} />
          <Route path={Routes.ProfileResale.path} element={<ProfileResale />} />
          <Route path={Routes.ProfileTransactions.path} element={<ProfileTransactions />} />
          <Route path={Routes.ProfileUndoResale.path} element={<ProfileUndoResale />} />
        </Route>
        <Route path={withLang(Routes.Promoter.path)} element={<Promoter />} />
        <Route path={withLang(Routes.PurchaseForm.path)} element={<PurchaseForm />} />
        <Route path={withLang(Routes.Refund.path)} element={<Refund />} />
        <Route path={withLang(Routes.Resale.path)} element={<Resale />} />
        <Route path={withLang(Routes.Search.path)} element={<Search />} />
        <Route path={withLang(Routes.Success.path)} element={<Success />} />
        <Route path={withLang(Routes.SupportConversation.path)} element={<SupportConversation />} />
        <Route path={withLang(Routes.SupportForm.path)} element={<SupportForm />} />
        <Route path={withLang(Routes.Terms.path)} element={<Terms />} />
        <Route path={withLang(Routes.TicketGeneration.path)} element={<TicketGeneration />} />
        <Route path={withLang(Routes.Users.path)}>
          <Route path={Routes.UsersPassword.path} element={<UsersPassword />}>
            <Route path={Routes.UsersPasswordReset.path} element={<UsersPasswordReset />} />
            <Route path={Routes.UsersPasswordUpdate.path} element={<UsersPasswordUpdate />} />
          </Route>
          <Route path={Routes.UsersEmail.path} element={<UsersEmail />}>
            <Route path={Routes.UsersEmailConfirm.path} element={<UsersEmailConfirm />} />
          </Route>
        </Route>
        <Route path={withLang(Routes.Wallet.path)} element={<Wallet />} />
        <Route path={withLang(Routes.NotFound.path)} element={<NotFound />} />
        <Route path="*" element={<Navigate to={Routes.NotFound.generatePath()} replace />} />
      </Route>
    )
    return createRoutesFromElements(children)
  }, [])

  const Router = useMemo<JSX.Element>(() => {
    const router = createBrowserRouter(routes)
    return <RouterProvider router={router} />
  }, [routes])

  return { Router }
}
